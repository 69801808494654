<template>
  <div>
    <div class="d-flex">
      <v-col class="d-flex w-full justify-center align-center pl-12 pt-9">
        <v-btn
          depressed
          icon
          class="back-btn mr-4"
          @click="goBack"
          v-if="$route.meta.hasBackButton"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <s-text
          v-if="header || $route.meta.title"
          class="flex"
          color="black"
          weight="bold"
          size="lg"
        >
          {{ header ? header : $route.meta.title }}
        </s-text>
        <v-skeleton-loader
          v-else
          class="flex"
          width="300"
          type="card-heading"
        />
      </v-col>
      <v-col class="d-flex align-center pt-7 pr-12 justify-end">
        <div class="mr-6" v-if="$route.meta.hasActionButton">
          <s-btn
            type="button"
            color="primary"
            class="max-width"
            elevation="0"
            :to="{ name: $route.meta.actionButton.linkName }"
          >
            <v-icon class="mr-1">{{ $route.meta.actionButton.icon }}</v-icon>
            {{ $route.meta.actionButton.title }}
          </s-btn>
        </div>
        <div class="d-flex align-center">
          <v-menu
            offset-y
            bottom
            :left="true"
            nudge-top="-10"
            transition="slide-y-transition"
          >
            <template v-slot:activator="{ on }">
              <div class="d-flex align-center" color="white" v-on="on">
                <v-avatar size="40" color="primary">
                  <span class="white--text"> P </span>
                </v-avatar>
                <s-text color="black" class="px-2" weight="normal" size="md">
                  Profile
                </s-text>
                <s-icon name="caret-down" size="16px" class="mr-2" />
              </div>
            </template>
            <v-list class="topbar__control">
              <v-list-item @click="logout">
                <s-icon name="arrow-circle-right" class="mr-3" />
                Logout
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-col>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { SimpleEventBus } from '@/services/core/EventBus'
export default {
  name: 'TopNav',
  data() {
    return {
      header: null,
    }
  },
  methods: {
    logout() {
      localStorage.clear('jwt')
      localStorage.clear('vuex')
      location.href = '/login'
    },
    goBack() {
      this.$router.go(-1)
    },
  },
  computed: {
    ...mapGetters({
      apps: 'app/apps',
      user: 'auth/user',
    }),
    emptyAppsList() {
      return this.apps.length < 1
    },
    routeName() {
      return this.$route.name
    },
  },
  created() {
    SimpleEventBus.$on('updateHeader', (data) => {
      this.header = data
    })
  },
}
</script>

<style lang="scss" scoped>
.back-btn {
  background: #f0f0f0;
}

::v-deep {
  .v-skeleton-loader__card-heading {
    background: none !important;
  }

  .v-skeleton-loader__heading {
    margin: 0;
  }
}
</style>
